:root {
  --primary-less-dark: rgb(61, 90, 128);
  --shade: rgb(51, 76, 107);
}

* {
  box-sizing: border-box;
}

.tech_container {
  background-color: var(--primary-less-dark);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding-top: 4em;
  padding-bottom: 4em;
}

.smaller_tech_heading {
  text-align: center;
  margin-top: 0.5em;
  font-weight: bolder;
}

.tech_heading {
  text-align: center;
  font-weight: bolder;
}

.main_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.list_indent {
  margin-top: 1rem;
  margin-left: 2rem;
  line-height: 1.5rem;
}

.mini_container {
  margin-top: 3em;
  margin-bottom: 3em;
  border-radius: 0.625em;
  border: 2px solid #fff;
  padding: 1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: 30%;
  min-width: 150px;
  background-color: var(--shade);
}

@media screen and (max-width: 960px) {
  .mini_container {
    width: 40%;
  }
}

@media screen and (max-width: 476px) {
  .main_container {
    flex-direction: column;
    align-items: center;
  }

  .mini_container {
    margin-top: 1.5em;
    width: 80%;
    margin-bottom: 0;
  }
}
