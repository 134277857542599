:root {
  --darkblue: rgb(41, 50, 65);
}

.outercontainer {
  background-color: #fff;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  object-fit: contain;
}

.innercontainer {
  justify-content: center;
  display: flex;
  flex-basis: 80%;
  height: fit-content;
}

.leftForm {
  flex-basis: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.leftForm > section {
  background-color: rgb(61, 90, 128);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: var(--darkblue) 2px solid;
  border-radius: 2em;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.contact_grouping {
  display: flex;
  justify-content: left;
  color: #000;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 4em;
  width: 95%;
}

.contact_grouping > p {
  margin-left: 2rem;
}

.btn-cv {
  margin-top: 2em;
  text-decoration: none;
  color: #fff;
  border: #fff 2px solid;
  padding: 1em;
  background-color: var(--darkblue);
  width: 50%;
  text-align: center;
  border-radius: 1em;
}

.btn-cv:hover {
  background-color: #fff;
  color: var(--darkblue);
  transition: all 0.3s ease-out;
  font-weight: bold;
  border: #000 2px solid;
}

.rightForm {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.rightForm > section {
  background-color: rgb(61, 90, 128);
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: var(--darkblue) 2px solid;
  border-radius: 2em;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 1200px) {
  .leftForm > section {
    width: 90%;
  }

  .rightForm > section {
    width: 90%;
  }
}

@media screen and (max-width: 1087px) {
  .leftForm > section {
    width: 95%;
  }

  .rightForm > section {
    width: 95%;
  }
}

@media screen and (max-width: 987px) {
  .innercontainer {
    flex-direction: column;
  }

  .leftForm > section {
    width: 80%;
  }

  .rightForm > section {
    width: 80%;
  }
}
