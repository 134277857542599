:root {
  --main-dark: rgb(41, 50, 65);
}

* {
  box-sizing: border-box;
  font-family: "Comfortaa", cursive;
}

.home-container {
  background: url("../images/tech-home.jpg") center center/cover no-repeat;
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  justify-content: center;
}

.home-container > h1 {
  color: #000;
  font-size: 4.5rem;
  margin-top: 1rem;
  text-align: center;
  font-weight: bolder;
}

.home-container > p {
  margin-top: 2em;
  font-size: 1.5rem;
}

.home_btns {
  margin-top: 1rem;
  text-decoration: none;
  background-color: #fff;
  padding: 1em;
  color: var(--main-dark);
  border: var(--main-dark) 2px solid;
  border-radius: 1em;
}

.home_btns:hover {
  border: #fff 2px solid;
  background-color: var(--main-dark);
  color: #fff;
}

@media screen and (max-width: 60rem) {
  .home-container > h1 {
    font-size: 4rem;
  }

  .home-container > p {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 48rem) {
  .home-container {
    height: 70vh;
  }

  .home-container > h1 {
    font-size: 3rem;
  }

  .home-container > p {
    font-size: 1.275rem;
  }
}

@media screen and (max-width: 500px) {
  .home-container {
    height: 70vh;
  }

  .home-container > h1 {
    font-size: 2.5rem;
  }

  .home-container > p {
    font-size: 1.275rem;
  }
}

.btn-mobile {
  display: block;
  text-decoration: none;
}

.btn {
  width: 100%;
}
