:root {
  --darkblue: rgb(41, 50, 65);
}

form {
  width: 100%;
}

.form-control {
  width: 100%;
  margin-right: 10em;
}

.ErrorMessages {
  font-size: 0.75rem;
  color: red;
  padding-bottom: 0.5rem;
  margin-top: -0.5rem;
  font-weight: bolder;
}

.successMessage {
  font-size: 0.75rem;
  color: rgb(89, 230, 89);
  padding-bottom: 0.5rem;
  margin-top: -0.5rem;
  font-weight: bolder;
}

.submit-btn {
  margin-top: 0.5em;
  color: #fff;
  padding: 1em;
  background-color: var(--darkblue);
  width: 100%;
  border: #fff 2px solid;
  border-radius: 1em;
}

.submit-btn:hover {
  color: #000;
  background-color: #fff;
  border: #000 2px solid;
  transition: all 0.3s ease-out;
}
