* {
  box-sizing: border-box;
}

.bg_white {
  display: flex;
  justify-content: center;
}

.template_container {
  margin-top: 1em;
  width: 80%;
  margin-bottom: 10em;
  line-height: 2rem;
}

.template_container > h1 {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.template_container > h2 {
  margin-bottom: 1rem;
}

.template_container > h3 {
  margin-bottom: 1rem;
}

.template_container > p {
  line-height: 2rem;
  margin-bottom: 1.5rem;
}

.image_with_caption {
  display: flex;
  justify-content: center;
}

.accordion {
  margin-bottom: 2em;
}
