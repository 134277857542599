:root {
  --var--accent-color: rgb(238, 108, 77);
}

* {
  box-sizing: border-box;
}

.image__item__pic-wrap {
  display: flex;
  flex-direction: column;
  width: min(70%, 700px);
  height: auto;
  border: 2px solid var(--var--accent-color);
  border-radius: 0.625em;
  padding: 0.375em 0.5em;
  margin-top: 1em;
  margin-bottom: 3em;
  max-width: 100%;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
  min-width: 250px;
}

.image__item__img {
  width: 100%;
  height: auto;
}

.image__item__text {
  margin-top: 1rem;
  text-align: center;
  font-style: italic;
  font-size: 0.8rem;
}
