.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.green_check {
  width: 25%;
}
