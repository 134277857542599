:root {
  --primary-dark: rgb(41, 50, 65);
  --primary-less-dark: rgb(61, 90, 128);
  --primary-less-less-dark: rgb(152, 193, 217);
  --primary-light-blue: rgb(224, 251, 252);
  --primary-accent: rgb(238, 108, 77);
}

.cards {
  padding: 4rem;
  background: #fff;
}

* {
  box-sizing: border-box;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 70em;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 3.125em 0 2.8125em;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 0.625em;
  width: 100%;
  background-color: var(--primary-light-blue);
}

.cards__item:hover {
  background-color: var(--primary-less-less-dark);
}

.cards__item__fixed {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  margin: 0 1rem;
  border-radius: 0.625em;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 0.375em 1.25em rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 0.375em 1.25em rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 0.375em 1.25em rgba(56, 125, 255, 0.017));
  border-radius: 0.625em;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 0.625em;
  padding: 0.375em 0.5em;
  max-width: calc((100%) - 3.75em);
  font-size: 0.75rem;
  font-weight: 700;
  color: #fff;
  background-color: rgb(37, 87, 87);
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 0.5em 1.875em 1.875em;
  display: flex;
  flex-direction: column;
}

.cards__item__text {
  color: #252e48;
  font-size: 1.125rem;
  line-height: 1.5rem;
  justify-content: left;
  width: 100%;
  margin-bottom: 3em;
}

.Active_Directory {
  border: 2px solid var(--primary-dark);
  color: white;
  font-size: 0.6rem;
  background-color: var(--primary-less-less-dark);
  padding: 0.5rem 0.5rem;
  width: 50%;
  border-radius: 2em;
  text-align: center;
  position: absolute;
  bottom: 2em;
}

.Remote {
  border: 2px solid var(--primary-dark);
  color: #fff;
  font-weight: bolder;
  font-size: 0.6rem;
  background-color: var(--primary-dark);
  padding: 0.5rem 0.5rem;
  width: 50%;
  border-radius: 2em;
  text-align: center;
  position: absolute;
  bottom: 2em;
}

.Virtual_Machine {
  border: 2px solid var(--primary-dark);
  color: white;
  font-size: 0.6rem;
  background-color: var(--primary-accent);
  padding: 0.5rem 0.5rem;
  width: 50%;
  border-radius: 2em;
  text-align: center;
  position: absolute;
  bottom: 2em;
}

.Microsoft_Azure {
  border: 2px solid var(--primary-dark);
  color: white;
  font-size: 0.6rem;
  background-color: rgb(153, 0, 0);
  padding: 0.5rem 0.5rem;
  width: 50%;
  border-radius: 2em;
  text-align: center;
  position: absolute;
  bottom: 2em;
}

.filter_buttons {
  margin-top: 2em;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (max-width: 1050px) {
  .Microsoft_Azure {
    font-size: 0.5rem;
  }

  .Remote {
    font-size: 0.5rem;
  }

  .Active_Directory {
    font-size: 0.5rem;
  }

  .Virtual_Machine {
    font-size: 0.5rem;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }

  .cards__items__fixed {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__items {
    margin-bottom: 2rem;
  }

  .cards__items__fixed {
    margin-bottom: 2rem;
  }

  .cards__item__link {
    margin-bottom: 1em;
  }
}

@media only screen and (max-width: 739px) {
  .cards__item__fixed {
    grid-gap: 1rem;
  }
}

@media only screen and (max-width: 671px) {
  .cards__item__fixed {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
}

@media only screen and (max-width: 503px) {
  .cards__item__fixed {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
  }
}
